import axios from "axios";
import setAuthToken from "../../util/setAuthToken";
import jwt_decode from "jwt-decode";
import config from "../../config";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING,SET_CURRENT_EMPLOYEE } from "../../constants/ActionTypes";
// Login - get user token
export const loginUser = userData => async dispatch => {
  
 const res =await axios
    .post(`${config.API_URL}/auth/login`, userData)
      // Save to localStorage
      // Set token to localStorage
      console.log(res)
      const { token } = res.data;

     
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
     
      // Set current user
      dispatch(setCurrentUser(decoded));
    
};




// Set logged in user
export const setCurrentUser = decode => {
  return {
    type: SET_CURRENT_USER,
    payload: decode
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

