import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import jwt_decode from "jwt-decode";
import setAuthToken from "./util/setAuthToken";
import { setCurrentUser, logoutUser } from "./appRedux/actions/AuthActions";


import configureStore, { history } from './appRedux/store';
//import App from "./containers/App/index";
import Admin from "./containers/App"
import AdminRoute from "./util/AdminRoute"
import SignIn from "./containers/SignIn"

const store = configureStore();

if (localStorage.jwtToken) {
  // Set auth token header auth

  const token = localStorage.jwtToken;
  
  setAuthToken(token);
  // Decode token and get user info and exp
 
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  console.log(decoded);
/*  if(decoded.role!=="company" || decoded.role!=="subcontractor"){
    console.log("Enter");
 var id=decoded.id;
    store.dispatch(getEmployee(id));  
  }*/
  // Set user and isAuthenticated
 
  // Check for expired token
  console.log(decoded);
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
    <div >
    
    <Route path="/login" component={SignIn}/>
      <Switch>
      
      <AdminRoute  path="/" component={Admin}/>
    
      </Switch>
      </div>
    
    </ConnectedRouter>
  </Provider>;


export default NextApp;
/*
  <EmployeeRoute  path="/employee" component={EmployeeApp}/>
      <SubContractorRoute  path="/subcontractor" component={SubContractorApp}/>
    */