import React,{useEffect} from "react";
import {Button, Checkbox, Form,  Input} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import setAuthToken from "../util/setAuthToken";
import jwt_decode from "jwt-decode";

import {
 
  loginUser
 
} from "../appRedux/actions/AuthActions";

const FormItem = Form.Item;

class SignIn extends React.PureComponent {
 

  componentDidMount() {
  
    if (this.props.auth.isAuthenticated) {
    if (this.props.auth.user.role == "admin") {
      this.props.history.push('/companies');
    }
    
  }
  }

componentWillReceiveProps(nextProps) {
  if (nextProps.auth.isAuthenticated) {
        
    if (nextProps.auth.user["role"] == "admin") {
      
      
      
      this.props.history.push("/companies"); // push user to dashboard when they login
    
   
  }

  if (nextProps.errors) {
    this.setState({
      errors: nextProps.errors
    });
  }
}
}
handleSubmit = (values) => {
 // e.preventDefault();
      this.props.loginUser(values);
  
};

  render() {
    
    return (
      <div className="gx-app-login-wrap" style={{marginTop:"15%"}}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">

              <img src={"https://s3.us-east-1.wasabisys.com/constructions/static/acts-round.png"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1>SignIn</h1>
            </div>

          </div>
          <div className="gx-app-login-content">
          <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={this.handleSubmit}
              className="gx-signin-form gx-form-row0">

              <Form.Item
               
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input placeholder="Email"/>
              </Form.Item>
              <Form.Item
             
                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                <Input type="password" placeholder="Password"/>
              </Form.Item>
                <Link to="/forgot" style={{marginTop:20,marginBottom:20,padding:10}}><span >Forgot Your Password ?</span></Link>  
                <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  signIn
                </Button>
                 </Form.Item>
              </Form>
            </div>

          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.authR,
  errors: state.errors
});
export default connect(mapStateToProps, {
  loginUser
})(SignIn);
