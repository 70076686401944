import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {logoutUser} from "../../appRedux/actions/AuthActions";
import {connect} from "react-redux";


const UserProfile = (props) => {
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
       <li onClick={() => props.logoutUser()}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
       
        <span className="gx-avatar-name">Admin<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};


const mapStateToProps = state => ({
  auth: state,

})

export default connect(mapStateToProps,{logoutUser})(UserProfile);
//export default UserProfile
