import React, {Component} from "react";
import {Layout} from "antd/lib/index";
import {Link} from "react-router-dom";
import Auxiliary from "util/Auxiliary";



const {Header} = Layout;

class Topbar extends Component {


 


  render() {
    return (
      <Auxiliary>
        <Header>
         
          <Link to="/login" className="gx-d-block gx-d-lg-none gx-pointer">
            Soo</Link>

                 </Header>
      </Auxiliary>
    );
  }
}


export default Topbar
