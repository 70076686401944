import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}companies`} component={asyncComponent(() => import('./SamplePage'))}/>
<Route path={`${match.url}subcontractors`} component={asyncComponent(() => import('./SamplePage/Second'))}/>
<Route path={`${match.url}reports`} component={asyncComponent(() => import('./SamplePage/Report'))}/>
<Route path={`${match.url}send-email`} component={asyncComponent(() => import('./SamplePage/Email'))}/>
    </Switch>
  </div>
);

export default App;
